import { Stack, Typography } from '@mui/material';
import { CreateMarkupDialogRow } from './CreateMarkupDialogRow';
import { useMarkupTFunction } from '@retail/markup/i18n';
import { calculateSalesCoverage } from '@retail/utils';
import { useMemo } from 'react';
import { MarkupCondition } from '@retail/calculus/types';
import dayjs from 'dayjs';

interface Props {
  markupCondition: MarkupCondition;
}

export function CurrentActiveMarkup({ markupCondition }: Props) {
  const t = useMarkupTFunction();

  const inheritedFrom = useMemo(() => markupCondition.getLevelName(), [markupCondition]);

  if (!markupCondition.id) return <></>;

  return (
    <Stack gap={2}>
      <Typography fontWeight={600}>
        {t('markup.activeMarkup')} ({inheritedFrom})
      </Typography>
      <CreateMarkupDialogRow
        name={t('markup.markupName')}
        value={markupCondition.markupTemplateName}
      />

      <CreateMarkupDialogRow
        name={t('markup.salesCoverage')}
        value={
          markupCondition.salesFactor
            ? `${calculateSalesCoverage(markupCondition?.salesFactor)}%`
            : '-'
        }
      />

      <CreateMarkupDialogRow name={t('markup.salesFactor')} value={markupCondition.salesFactor} />
      <CreateMarkupDialogRow
        name={t('markup.fromDate')}
        value={dayjs(markupCondition.validFrom).format('ll')}
      />
      <CreateMarkupDialogRow
        name={t('markup.toDate')}
        value={markupCondition.validTo ? dayjs(markupCondition.validTo).format('ll') : '-'}
      />
    </Stack>
  );
}
