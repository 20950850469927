import { Divider, Stack, Typography } from '@mui/material';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import { useCallback } from 'react';
import { MyAssortmentFilters } from '@retail/my-assortment/containers';
import { useDisclosure } from '@shared/hooks';
import { AddItemsButton, ExcludeVatSwitch } from '@retail/my-assortment/components';
import { useAssortmentFilters, useIncludeVatMyAssortment } from '@retail/my-assortment/context';
import { Assortment } from '@retail/my-assortment/types';
import { AssortmentItemsContainer } from './AssortmentItemsContainer';
import { AddItemsToAssortment } from './AddItemsToAssortment';

interface Props {
  baseAssortment: Assortment;
}

export function BrandAssortmentContainer({ baseAssortment }: Props) {
  const { isOpen: isAddItemsDialogOpen, onToggle: toggleAddItemsDialog } = useDisclosure(false);
  const { includeVat, toggleIncludeVat } = useIncludeVatMyAssortment();
  const { isOpen: showFilters, onToggle: toggleFilters } = useDisclosure(true);

  const { searchFilters, setSearchFilters, filterOptions, resetFilters } = useAssortmentFilters({
    assortmentId: baseAssortment.id,
  });

  const onToggleAddItemsDialog = useCallback(() => {
    toggleAddItemsDialog();
    resetFilters();
  }, [resetFilters, toggleAddItemsDialog]);

  return (
    <Stack flexGrow={1} overflow="hidden">
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center" pt={2} pr={2}>
        <Typography variant="h6" pl={2}>
          {baseAssortment.title}
        </Typography>
        <Stack flexDirection="row" gap={1} pb={1} alignSelf="flex-end">
          <ExcludeVatSwitch toggleVat={includeVat} handleToggleVat={toggleIncludeVat} />

          <AddItemsButton onClick={onToggleAddItemsDialog} />
          {isAddItemsDialogOpen && (
            <AddItemsToAssortment
              closeDialog={onToggleAddItemsDialog}
              baseAssortmentId={baseAssortment.id}
              resetFilters={resetFilters}
            />
          )}
        </Stack>
      </Stack>

      <Divider />
      <Stack flexGrow={1} overflow="hidden" flexDirection="row">
        <SuspenseWithSentryErrorBoundary>
          <AssortmentItemsContainer
            assortmentId={baseAssortment.id}
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
            showFilters={showFilters}
            toggleFilters={toggleFilters}
            toggleAddItemsDialog={onToggleAddItemsDialog}
          />
        </SuspenseWithSentryErrorBoundary>
        {showFilters && (
          <MyAssortmentFilters
            {...searchFilters}
            {...setSearchFilters}
            {...filterOptions}
            showFilters={showFilters}
            closeFilters={toggleFilters}
          />
        )}
      </Stack>
    </Stack>
  );
}
