import { MarkupConditionDTO, PackageType } from '../dtos';
import { CalculusAreas } from './CalculusAreas';
import { Operation } from './Conditions';
import { ConditionBase, ConditionBaseProps } from './ConditionBase';
import { Condition } from './Condition';

interface MarkupConditionProps extends ConditionBaseProps<MarkupCondition> {
  markupTemplateName: string;
  markupTemplateId: number;
  salesPriceCoverage: number;
  salesFactor: number;
  markupGtin?: number;
  markupPackageType?: PackageType;
}

export class MarkupCondition extends ConditionBase<MarkupCondition> {
  markupTemplateName: string;
  markupTemplateId: number;
  salesPriceCoverage: number;
  salesFactor: number;
  markupGtin?: number;
  markupPackageType?: PackageType;

  constructor({
    markupTemplateName,
    markupTemplateId,
    salesPriceCoverage,
    salesFactor,
    markupGtin,
    markupPackageType,
    ...superProps
  }: MarkupConditionProps) {
    super(superProps);

    this.markupTemplateId = markupTemplateId;
    this.markupTemplateName = markupTemplateName;
    this.salesPriceCoverage = salesPriceCoverage;
    this.salesFactor = salesFactor;
    this.markupGtin = markupGtin;
    this.markupPackageType = markupPackageType;
  }

  static fromDto = (dto: MarkupConditionDTO): MarkupCondition =>
    new MarkupCondition({
      id: dto.id,
      assortmentId: dto.assortmentId,
      assortmentType: dto.assortmentType,
      mgSupplierId: dto.mgSupplierId,
      levelProductGroup: dto.levelProductGroup,
      levelProductGroupName: dto.levelProductGroupName,
      mgItemNumber: dto.mgItemNumber,
      finfoNumber: dto.finfoNumber,
      mgSupplierName: dto.mgSupplierName,
      mgSupplierItemId: dto.mgSupplierItemId,
      primaryText: dto.primaryText,
      secondaryText: dto.secondaryText,
      validFrom: dto.validFrom ? new Date(dto.validFrom) : undefined,
      validTo: dto.validTo ? new Date(dto.validTo) : undefined,
      active: dto.active,
      current: dto.current,
      inherited: dto.inherited,
      source: dto.source,
      markupTemplateName: dto.markupTemplateName,
      markupTemplateId: dto.markupTemplateId,
      salesPriceCoverage: dto.salesPriceCoverage,
      salesFactor: dto.salesFactor,
      futureConditions: dto.futureConditions?.map(MarkupCondition.fromDto),
      subConditions: dto.subConditions?.map(MarkupCondition.fromDto),
      replacesConditions: dto.replacesConditions?.map(MarkupCondition.fromDto),
      newCondition: dto.newCondition && MarkupCondition.fromDto(dto.newCondition),
      operation: dto.operation as Operation,
      markupGtin: dto.markupGtin,
      markupPackageType: dto.markupPackageType,
      condition: Condition.fromDto(dto.condition),
    });

  getType = () => CalculusAreas.MARKUP;
}
