import { useAppTFunction } from '@retail/app/i18n';
import {
  useDeleteCondition,
  useFetchConditions,
  useMutateActivateDraftedConditions,
} from '@retail/calculus/data-access';
import { Conditions } from '@retail/calculus/types';
import { useToast } from '@retail/hooks';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

export const useCalculusConditions = (isOpen?: boolean) => {
  const t = useAppTFunction();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { draftAssortmentId, departmentId } = useDepartmentAssortments();

  // Agreements
  const { data: draftConditionsDTO, isLoading: conditionsIsLoading } = useFetchConditions({
    assortmentId: draftAssortmentId,
    refetchInterval: isOpen ? 3000 : undefined,
  });

  const draftConditions =
    draftConditionsDTO && draftConditionsDTO.map((condition) => Conditions.fromDto(condition));

  const purchaseConditions = useMemo(
    () => draftConditions?.map((condition) => condition.purchaseConditions)?.flat() ?? [],
    [draftConditions]
  );
  const freightConditions = useMemo(
    () => draftConditions?.map((condition) => condition.freightConditions)?.flat() ?? [],
    [draftConditions]
  );
  const markupConditions = useMemo(
    () => draftConditions?.map((condition) => condition.markupConditions)?.flat() ?? [],
    [draftConditions]
  );

  const conditionsLength = useMemo(
    () => purchaseConditions.length + freightConditions.length + markupConditions.length,
    [freightConditions.length, markupConditions.length, purchaseConditions.length]
  );

  const handleInvalidateQueries = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
    queryClient.invalidateQueries({ queryKey: ['mg-item'] });
    queryClient.invalidateQueries({ queryKey: ['product-groups-hierarchy'] });
  }, [queryClient]);

  // Remove conditions from draft drawer
  const { mutateAsync: removeConditions, isLoading: removeConditionsIsLoading } =
    useDeleteCondition({
      assortmentId: draftAssortmentId,
      departmentId,
    });

  const handleDeleteConditions = (ids: number[]) => {
    removeConditions({ body: ids })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
        queryClient.invalidateQueries({ queryKey: ['product-groups-hierarchy'] });

        if (ids.length === 1) toast.success(t('calculus.messages.conditionDeleted'));
        else toast.success(t('calculus.messages.conditionsDeleted'));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('markup.error.generic'));
      });
  };

  // Activate conditions in draft drawer
  const { mutateAsync: activateDraftCondition, isLoading: activateConditionIsLoading } =
    useMutateActivateDraftedConditions({
      assortmentId: draftAssortmentId,
      departmentId,
    });

  const activateDraft = useCallback(
    (conditionIds?: number[]) => {
      activateDraftCondition({ conditionIds })
        .then(() => {
          toast.success(t('calculus.messages.success'));
          handleInvalidateQueries();
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('markup.error.generic'));
        });
    },
    [activateDraftCondition, handleInvalidateQueries, t, toast]
  );

  return {
    conditionsLength,
    draftConditions,
    draftPurchaseConditions: purchaseConditions,
    draftFreightConditions: freightConditions,
    draftMarkupConditions: markupConditions,
    draftConditionsIsLoading: conditionsIsLoading,
    activateConditionIsLoading,
    removeConditionsIsLoading,
    handleDeleteConditions,
    activateDraft,
  };
};
