import { ConditionsDTO } from '../dtos';
import { FreightCondition } from './FreightCondition';
import { MarkupCondition } from './MarkupCondition';
import { PurchaseCondition } from './PurchaseCondition';

export enum PriceType {
  GTIN = 'GTIN',
  NET = 'NET',
  REBATE = 'REBATE',
}

export enum Operation {
  UPSERT = 'UPSERT',
  DELETE = 'DELETE',
}

export enum AssortmentType {
  BASE = 'BASE',
  DRAFT = 'DRAFT',
}

interface ConditionsProps {
  mgSupplierId: number;
  purchaseConditions: PurchaseCondition[];
  freightConditions: FreightCondition[];
  markupConditions: MarkupCondition[];
}

export class Conditions {
  mgSupplierId: number;
  purchaseConditions: PurchaseCondition[];
  freightConditions: FreightCondition[];
  markupConditions: MarkupCondition[];

  constructor({
    mgSupplierId,
    purchaseConditions,
    freightConditions,
    markupConditions,
  }: ConditionsProps) {
    this.mgSupplierId = mgSupplierId;
    this.purchaseConditions = purchaseConditions;
    this.freightConditions = freightConditions;
    this.markupConditions = markupConditions;
  }

  static fromDto = (dto: ConditionsDTO): Conditions =>
    new Conditions({
      mgSupplierId: dto.mgSupplierId,
      purchaseConditions: dto.purchaseConditions.map((condition) =>
        PurchaseCondition.fromDto(condition)
      ),
      freightConditions: dto.freightConditions.map((condition) =>
        FreightCondition.fromDto(condition)
      ),
      markupConditions: dto.markupConditions.map((condition) => MarkupCondition.fromDto(condition)),
    });
}
