import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { ColumnHeaderWithHelpText, DataGridPagination, StackedTextCell } from '@retail/components';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { ItemAgreement, PriceListPageResult } from '@retail/purchase-agreements/types';
import dayjs from 'dayjs';
import { InfinityIcon } from '@shared/custom-icons';

export interface PriceTableProps {
  result: PriceListPageResult;
  updatePage: Dispatch<SetStateAction<number>>;
  updatePageSize: Dispatch<SetStateAction<number>>;
}

const baseColumnProps: Partial<GridColDef> = {
  width: 150,
  headerName: '',
  headerAlign: 'left',
  align: 'left',
};

export function PriceListTable({ result }: PriceTableProps) {
  const t = usePurchaseAgreementsTFunction();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 400,
    page: 0,
  });

  const columns: GridColDef[] = useMemo(() => {
    const nameColDef: GridColDef<ItemAgreement> = {
      ...baseColumnProps,
      field: 'name',
      width: 280,
      headerName: t('purchaseAgreements.agreement.columns.primaryText'),
      renderCell: ({ row }) => (
        <StackedTextCell topLine={row.primaryText} bottomLine={row.secondaryText} />
      ),
    };

    const mgItemIdColDef: GridColDef<ItemAgreement> = {
      ...baseColumnProps,
      field: 'mgItemId',
      width: 180,
      headerName: `
        ${t('purchaseAgreements.agreement.columns.mgItemNr')} /
        ${t('purchaseAgreements.agreement.columns.finfoNr')}`,
      renderHeader: () => {
        return (
          <StackedTextCell
            centerAlign
            topLine={`${t('purchaseAgreements.agreement.columns.mgItemNr')}/`}
            bottomLine={t('purchaseAgreements.agreement.columns.finfoNr')}
          />
        );
      },
      renderCell: ({ row }) => (
        <StackedTextCell topLine={row.mgItemNumber} bottomLine={row.finfoNumber} />
      ),
    };

    const priceColDef: GridColDef<ItemAgreement> = {
      ...baseColumnProps,
      field: 'price',
      headerName: t('purchaseAgreements.agreement.columns.price'),
      renderCell: ({ row: { price, priceUnit } }) =>
        t('purchaseAgreements.pricePerUnit', {
          price: price.toFixed(2),
          unit: priceUnit,
        }),
    };

    const basePriceUnitColDef: GridColDef<ItemAgreement> = {
      field: 'gtinPriceUnit',
      width: 150,
      renderHeader: () => (
        <ColumnHeaderWithHelpText
          header={t('purchaseAgreements.agreement.columns.basePriceUnit')}
          tooltipContent={t('purchaseAgreements.agreement.columns.basePriceUnitDesc')}
        />
      ),
      renderCell: ({ row }) => row.gtinPriceUnit || row.priceUnit,
    };

    const fromDateUnitColDef: GridColDef<ItemAgreement> = {
      field: 'validFrom',
      width: 150,
      headerName: t('purchaseAgreements.agreement.columns.validFrom'),
      renderCell: ({ row }) => dayjs(row.validFrom).format('ll'),
    };

    const toDateUnitColDef: GridColDef<ItemAgreement> = {
      field: 'validTo',
      width: 150,
      headerName: t('purchaseAgreements.agreement.columns.validTo'),
      renderCell: ({ row }) => {
        //Year is set to 9999 if there is no end date
        const year = new Date(row.validTo).getFullYear();
        if (year === 9999) {
          return <InfinityIcon />;
        }
        return row.validTo ? dayjs(row.validTo).format('ll') : <InfinityIcon />;
      },
    };

    return [
      nameColDef,
      mgItemIdColDef,
      priceColDef,
      basePriceUnitColDef,
      fromDateUnitColDef,
      toDateUnitColDef,
    ];
  }, [t]);

  return (
    <DataGridPremium
      disableRowSelectionOnClick
      rows={result.items}
      getRowId={(row) => `${row.mgSupplierItemId} ${row.price} ${row.gtinPrice}`}
      columns={columns}
      pagination={true}
      paginationMode="server"
      slots={{
        pagination: () => <DataGridPagination isDialog />,
      }}
      rowCount={result.totalItems}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sx={{
        '& .MuiDataGrid-row': {
          ':last-of-type': { borderBottomLeftRadius: 24, borderBottomRightRadius: 24 },
        },
      }}
    />
  );
}
