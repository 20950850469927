export interface CreateSupplierLevelMarkupCondition {
  validFrom: Date;
  templateName: string;
  salesFactor: number;
}

export interface CreateProductGroupLevelMarkupCondition extends CreateSupplierLevelMarkupCondition {
  groupNumber: string;
}

export interface CreateDiscountGroupLevelMarkupCondition
  extends CreateSupplierLevelMarkupCondition {
  groupCode: string;
}

export interface CreateMgItemLevelMarkupCondition extends CreateSupplierLevelMarkupCondition {
  mgItemNumber: number;
  gtin?: number;
}

export interface CreateMgItemLevelSalesPriceCondition
  extends Omit<CreateMgItemLevelMarkupCondition, 'salesFactor'> {
  salesPrice: number;
  incVat: boolean;
  unit?: string;
}

export interface DeactivateCondition {
  mgSupplierLevel: boolean;
  groupNumber?: string;
  discountGroupCode?: string;
  mgItemNumber?: number;
  gtin?: number;
  fromDate?: Date;
}

export function isCreateMgItemLevelSalesPriceCondition(
  condition: CreateMgItemLevelSalesPriceCondition | CreateMgItemLevelMarkupCondition
): condition is CreateMgItemLevelSalesPriceCondition {
  return (condition as CreateMgItemLevelSalesPriceCondition).salesPrice !== undefined;
}
