import { useMutateCreateOrUpdateConditions } from '@retail/calculus/data-access';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useToast } from '@retail/hooks';
import {
  CreateDiscountGroupLevelMarkupCondition,
  CreateMgItemLevelMarkupCondition,
  CreateMgItemLevelSalesPriceCondition,
  CreateProductGroupLevelMarkupCondition,
  CreateSupplierLevelMarkupCondition,
  DeactivateCondition,
  isCreateMgItemLevelSalesPriceCondition,
  MarkupConditionCreateDTO,
} from '@retail/markup/types';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
  mapMarkupConditionToDto,
  mapSalesPriceConditionToDto,
  truncateSalesFactorMaxDecimals,
} from './conditionToDto';

interface Props {
  mgSupplierId: number;
}

export function useMarkupActions({ mgSupplierId }: Props) {
  const t = useCalculusTFunction();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { draftAssortmentId, departmentId } = useDepartmentAssortments();

  const invalidateMarkups = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
    queryClient.invalidateQueries({ queryKey: ['product-groups-hierarchy'] });
  }, [queryClient]);

  const { mutateAsync: mutatePostMarkupChanges, isLoading: isLoadingPostChanges } =
    useMutateCreateOrUpdateConditions({ assortmentId: draftAssortmentId, departmentId });

  const deactivateMarkupCondition = useCallback(
    (deactivateCondition: DeactivateCondition) => {
      mutatePostMarkupChanges({
        body: {
          markupConditions: [
            {
              mgSupplierId,
              mgSupplierConditions:
                deactivateCondition.mgSupplierLevel &&
                !deactivateCondition.groupNumber &&
                !deactivateCondition.discountGroupCode &&
                !deactivateCondition.mgItemNumber
                  ? [{ validFrom: new Date().toISOString(), markup: {} }]
                  : undefined,
              productGroupConditions: deactivateCondition.groupNumber
                ? [
                    {
                      groupNumber: deactivateCondition.groupNumber,
                      validFrom: new Date().toISOString(),
                      markup: {},
                    },
                  ]
                : undefined,
              discountGroupConditions: deactivateCondition.discountGroupCode
                ? [
                    {
                      groupCode: deactivateCondition.discountGroupCode,
                      validFrom: new Date().toISOString(),
                      markup: {},
                    },
                  ]
                : undefined,
              mgSupplierItemConditions: deactivateCondition.mgItemNumber
                ? [
                    {
                      mgItemNumber: deactivateCondition.mgItemNumber,
                      validFrom: new Date().toISOString(),
                      gtinCode: deactivateCondition.gtin,
                      markup: {},
                    },
                  ]
                : undefined,
            },
          ],
        },
      })
        .then(() => {
          toast.success(t('calculus.actions.conditionDeleteInDrawer'));
          invalidateMarkups();
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('calculus.error.generic'));
        });
    },
    [invalidateMarkups, mgSupplierId, mutatePostMarkupChanges, t, toast]
  );

  const handleCreateConditionResponse = useCallback(
    (response: Promise<void>) =>
      response
        .then(() => {
          toast.success(t('calculus.actions.conditionCreateInDrawer'));
          invalidateMarkups();
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('calculus.error.generic'));
        }),
    [invalidateMarkups, t, toast]
  );

  const createSupplierLevelMarkupCondition = useCallback(
    ({ validFrom, templateName, salesFactor }: CreateSupplierLevelMarkupCondition) =>
      handleCreateConditionResponse(
        mutatePostMarkupChanges({
          body: {
            markupConditions: [
              {
                mgSupplierId,
                mgSupplierConditions: [
                  {
                    validFrom: validFrom.toISOString(),
                    markup: {
                      templateCreate: {
                        templateName,
                        salesFactor: truncateSalesFactorMaxDecimals(salesFactor),
                      },
                    },
                  },
                ],
              },
            ],
          },
        })
      ),
    [handleCreateConditionResponse, mgSupplierId, mutatePostMarkupChanges]
  );

  const createProductGroupLevelMarkupCondition = useCallback(
    ({
      validFrom,
      groupNumber,
      templateName,
      salesFactor,
    }: CreateProductGroupLevelMarkupCondition) =>
      handleCreateConditionResponse(
        mutatePostMarkupChanges({
          body: {
            markupConditions: [
              {
                mgSupplierId,
                productGroupConditions: [
                  {
                    validFrom: validFrom.toISOString(),
                    groupNumber,
                    markup: {
                      templateCreate: {
                        templateName,
                        salesFactor: truncateSalesFactorMaxDecimals(salesFactor),
                      },
                    },
                  },
                ],
              },
            ],
          },
        })
      ),
    [handleCreateConditionResponse, mgSupplierId, mutatePostMarkupChanges]
  );

  const createDiscountGroupLevelMarkupCondition = useCallback(
    ({
      validFrom,
      groupCode,
      templateName,
      salesFactor,
    }: CreateDiscountGroupLevelMarkupCondition) =>
      handleCreateConditionResponse(
        mutatePostMarkupChanges({
          body: {
            markupConditions: [
              {
                mgSupplierId,
                discountGroupConditions: [
                  {
                    validFrom: validFrom.toISOString(),
                    groupCode,
                    markup: {
                      templateCreate: {
                        templateName,
                        salesFactor: truncateSalesFactorMaxDecimals(salesFactor),
                      },
                    },
                  },
                ],
              },
            ],
          },
        })
      ),
    [handleCreateConditionResponse, mgSupplierId, mutatePostMarkupChanges]
  );

  const createMgItemLevelMarkupCondition = useCallback(
    (condition: CreateMgItemLevelMarkupCondition) =>
      handleCreateConditionResponse(
        mutatePostMarkupChanges({
          body: {
            markupConditions: [mapMarkupConditionToDto(condition, mgSupplierId)],
          },
        })
      ),
    [handleCreateConditionResponse, mgSupplierId, mutatePostMarkupChanges]
  );

  const createMgItemLevelSalesPriceCondition = useCallback(
    (condition: CreateMgItemLevelSalesPriceCondition) =>
      handleCreateConditionResponse(
        mutatePostMarkupChanges({
          body: {
            markupConditions: [mapSalesPriceConditionToDto(condition, mgSupplierId)],
          },
        })
      ),
    [handleCreateConditionResponse, mgSupplierId, mutatePostMarkupChanges]
  );

  const createMultipleMgItemLevelConditions = useCallback(
    (conditions: (CreateMgItemLevelSalesPriceCondition | CreateMgItemLevelMarkupCondition)[]) => {
      const conditionsDto: MarkupConditionCreateDTO[] = conditions.map((condition) => {
        if (isCreateMgItemLevelSalesPriceCondition(condition)) {
          return mapSalesPriceConditionToDto(condition, mgSupplierId);
        } else {
          return mapMarkupConditionToDto(condition, mgSupplierId);
        }
      });

      return handleCreateConditionResponse(
        mutatePostMarkupChanges({
          body: {
            markupConditions: conditionsDto,
          },
        })
      );
    },
    [handleCreateConditionResponse, mgSupplierId, mutatePostMarkupChanges]
  );

  return {
    createSupplierLevelMarkupCondition,
    createProductGroupLevelMarkupCondition,
    createDiscountGroupLevelMarkupCondition,
    createMgItemLevelMarkupCondition,
    createMgItemLevelSalesPriceCondition,
    createMultipleMgItemLevelConditions,
    mutatePostMarkupChanges,
    deactivateMarkupCondition,
    isLoadingPostChanges,
  };
}
