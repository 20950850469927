import { PurchaseConditionDTO } from '../dtos';
import { CalculusAreas } from './CalculusAreas';
import { Operation, PriceType } from './Conditions';
import { ConditionBase, ConditionBaseProps } from './ConditionBase';
import { Condition } from './Condition';

interface PurchaseConditionProps extends ConditionBaseProps<PurchaseCondition> {
  agreementId: number;
  agreementText: string;
  priceType?: PriceType;
  standardAgreement: boolean;
  agreementUpdatedAt: string;
  agreementValidFrom: string;
  channel: string;
}

export class PurchaseCondition extends ConditionBase<PurchaseCondition> {
  agreementId: number;
  agreementText: string;
  priceType?: PriceType;
  standardAgreement: boolean;
  agreementUpdatedAt: string;
  agreementValidFrom: string;
  channel: string;

  constructor({
    agreementId,
    agreementText,
    priceType,
    standardAgreement,
    agreementUpdatedAt,
    agreementValidFrom,
    channel,
    ...superProps
  }: PurchaseConditionProps) {
    super(superProps);
    this.agreementId = agreementId;
    this.agreementText = agreementText;
    this.priceType = priceType;
    this.standardAgreement = standardAgreement;
    this.agreementUpdatedAt = agreementUpdatedAt;
    this.agreementValidFrom = agreementValidFrom;
    this.channel = channel;
  }

  static fromDto = (dto: PurchaseConditionDTO): PurchaseCondition =>
    new PurchaseCondition({
      id: dto.id,
      assortmentId: dto.assortmentId,
      assortmentType: dto.assortmentType,
      mgSupplierId: dto.mgSupplierId,
      levelProductGroup: dto.levelProductGroup,
      levelProductGroupName: dto.levelProductGroupName,
      mgItemNumber: dto.mgItemNumber,
      finfoNumber: dto.finfoNumber,
      mgSupplierName: dto.mgSupplierName,
      mgSupplierItemId: dto.mgSupplierItemId,
      primaryText: dto.primaryText,
      secondaryText: dto.secondaryText,
      validFrom: dto.validFrom ? new Date(dto.validFrom) : undefined,
      validTo: dto.validTo ? new Date(dto.validTo) : undefined,
      active: dto.active,
      current: dto.current,
      agreementId: dto.agreementId,
      agreementText: dto.agreementText,
      priceType: dto.priceType,
      inherited: dto.inherited,
      source: dto.source,
      standardAgreement: dto.standardAgreement,
      agreementUpdatedAt: dto.agreementUpdatedAt,
      agreementValidFrom: dto.agreementValidFrom,
      channel: dto.channel,
      futureConditions: dto.futureConditions?.map(PurchaseCondition.fromDto),
      subConditions: dto.subConditions?.map(PurchaseCondition.fromDto),
      replacesConditions: dto.replacesConditions?.map(PurchaseCondition.fromDto),
      newCondition: dto.newCondition && PurchaseCondition.fromDto(dto.newCondition),
      operation: dto.operation as Operation,
      condition: Condition.fromDto(dto.condition),
    });

  getType = () => CalculusAreas.PURCHASE;
}
