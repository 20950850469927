import { useMemo, useState } from 'react';
import { Supplier } from '@retail/suppliers/types';
import { CalculusAreas, ConditionState, FreightCondition } from '@retail/calculus/types';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { EditFreightConditionDialog } from './EditFreightCondition';
import { useLevelSearchParams } from '@retail/calculus/hooks';
import { CreateFreightConditionContainer } from './CreateFreightCondition';
import { AffectedItemsContainer } from '@retail/calculus/containers';
import { useFetchConditions } from '@retail/calculus/data-access';
import { FreightConditionsTable } from './FreightConditionsTable';
import { DeleteFreightDialog } from './DeleteFreightCondition';
import { AddButton, CalculusActionPortal, DeleteDialog } from '@retail/components';
import { useCalculusConditions } from '@retail/calculus/context';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useLanguageSelector } from '@retail/app/hooks';
import { Stack } from '@mui/material';

interface Props {
  supplier: Supplier;
  typesToShow: ConditionState;
  isCreateOpen: boolean;
  toggleCreateDialog: () => void;
}

export const FreightConditions = ({
  supplier,
  typesToShow,
  isCreateOpen,
  toggleCreateDialog,
}: Props) => {
  const t = useCalculusTFunction();
  const { language } = useLanguageSelector();

  const { draftAssortmentId, baseAssortmentId } = useDepartmentAssortments();
  const { selectedProductGroupNumber, selectedMgItemId } = useLevelSearchParams();
  const { handleDeleteConditions } = useCalculusConditions();

  const [conditionForAffectedItemsView, setConditionForAffectedItemsView] = useState<
    FreightCondition | undefined
  >(undefined);
  const [conditionForEdit, setConditionForEdit] = useState<FreightCondition | undefined>(undefined);
  const [conditionIdToDelete, setConditionIdToDelete] = useState<undefined | number>(undefined);
  const [conditionForDeactivation, setConditionForDeactivation] = useState<
    FreightCondition | undefined
  >(undefined);

  const handleEdit = (item?: FreightCondition) => setConditionForEdit(item);
  const handleDeactivate = (item?: FreightCondition) => setConditionForDeactivation(item);
  const handleDelete = (conditionId?: number) => setConditionIdToDelete(conditionId);

  const { data: conditionDtos = [] } = useFetchConditions({
    assortmentId: typesToShow === ConditionState.ACTIVE ? baseAssortmentId : draftAssortmentId,
    conditionTypes: ['FREIGHT'],
    conditionState: typesToShow === ConditionState.DRAFT ? ConditionState.ALL : typesToShow,
    mgSupplierIds: [supplier.mgSupplierId],
    groupNumbers: selectedProductGroupNumber ? [selectedProductGroupNumber] : [],
    mgItemNumbers: selectedMgItemId ? [selectedMgItemId] : [],
    includeOwnerConditions: typesToShow !== ConditionState.DRAFT,
  });

  const freightCondition = useMemo(
    () => (conditionDtos?.length > 0 ? conditionDtos[0].freightConditions : []),
    [conditionDtos]
  );
  const freightConditions = useMemo(
    () => freightCondition.map(FreightCondition.fromDto),
    [freightCondition]
  );
  const activeFreightConditions = freightConditions.filter(
    (condition) => condition.operation !== 'DELETE'
  );

  return (
    <Stack
      flexGrow={1}
      gap={2}
      overflow="auto"
      borderTop={({ palette }) => `1px solid ${palette.divider}`}
    >
      <CalculusActionPortal>
        <AddButton onClick={toggleCreateDialog}>{t('calculus.createFreightCondition')}</AddButton>
      </CalculusActionPortal>
      <FreightConditionsTable
        supplier={supplier}
        language={language}
        freightConditions={activeFreightConditions}
        editCondition={handleEdit}
        deactivateCondition={handleDeactivate}
        deleteCondition={handleDelete}
        setConditionForAffectedItemsView={setConditionForAffectedItemsView}
      />

      {isCreateOpen && (
        <CreateFreightConditionContainer
          supplierId={supplier.mgSupplierId}
          closeCreateDialog={toggleCreateDialog}
        />
      )}

      {conditionForDeactivation && (
        <DeleteFreightDialog
          isOpen
          conditionToDelete={conditionForDeactivation}
          onClose={() => handleDeactivate()}
        />
      )}
      {conditionForEdit && (
        <EditFreightConditionDialog
          isOpen
          supplierId={supplier.mgSupplierId}
          freightConditionToEdit={conditionForEdit}
          onClose={() => handleEdit()}
        />
      )}
      {conditionIdToDelete && (
        <DeleteDialog
          isOpen
          dialogTitle={t('calculus.actions.deleteFromDraft')}
          message={t('calculus.actions.confirmRemoval')}
          onDelete={() => handleDeleteConditions([conditionIdToDelete])}
          onClose={() => handleDelete()}
        />
      )}
      {conditionForAffectedItemsView && (
        <AffectedItemsContainer
          open
          onClose={() => setConditionForAffectedItemsView(undefined)}
          conditionType={CalculusAreas.FREIGHT}
          productGroup={conditionForAffectedItemsView?.condition.productGroup?.groupNumber}
          discountGroupId={conditionForAffectedItemsView?.condition.discountGroup?.id}
          mgSupplierId={supplier.mgSupplierId}
        />
      )}
    </Stack>
  );
};
