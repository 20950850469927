import { DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { AddItemsTabs, UploadAssortmentContainer } from '@retail/my-assortment/components';
import { useMutateAddItemsToDraft } from '@retail/my-assortment/data-access';
import { DraftTabs, ItemsDTO, ItemWithPriority } from '@retail/my-assortment/types';
import { isValidItem } from '@retail/my-assortment/utils';
import { MgSupplierItemSearchContainer } from '@retail/products/containers';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { CentralAssortmentOverview } from '../CentralAssortments/CentralAssortmentOverview';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { useAppTFunction } from '@retail/app/i18n';
import { useToast } from '@retail/hooks';
import { useAssortmentImport } from '@retail/my-assortment/context';
import {
  SuspenseWithSentryErrorBoundary,
  withDialogSuspenseErrorBoundary,
} from '@retail/components';

interface Props {
  memberId: string;
  draftAssortmentId: number;
  open: boolean;
  onClose: () => void;
  resetFilters: () => void;
}

function AddItemsToDraft({ memberId, draftAssortmentId, onClose, resetFilters, open }: Props) {
  const t = useAppTFunction();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [selectedTab, setSelectedTab] = useState<DraftTabs>(DraftTabs.ADD_ITEMS);
  const [checkedItems, setCheckedItems] = useState<MgSupplierItemSearchResult[]>([]);
  const [checkedAssortmentItems, setCheckedAssortmentItems] = useState<ItemWithPriority[]>([]);

  const { mutateAsync: postItems, isLoading: loadingPostItems } = useMutateAddItemsToDraft({
    memberId,
    assortmentId: draftAssortmentId,
  });

  const handleSubmitSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['assortmentItems'] });
    onClose();
  }, [onClose, queryClient]);

  const onSubmit = useCallback(
    (selectedItems: MgSupplierItemSearchResult[]) => {
      const items = selectedItems.map((item) => {
        return {
          mgSupplierId: item.mgParticipantAccountId,
          mgItemNumber: item.mgItemNumber,
          priority: 'ZERO',
        } as ItemsDTO;
      });
      postItems({ body: items })
        .then(() => {
          setCheckedItems([]);
          handleSubmitSuccess();
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('products.errors.generic'));
        });
    },
    [handleSubmitSuccess, postItems, t, toast]
  );

  const onSubmitCentralAssortmentItems = useCallback(
    (selectedItems: ItemWithPriority[]) => {
      postItems({ body: selectedItems })
        .then(() => {
          setCheckedItems([]);
          handleSubmitSuccess();
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('products.errors.generic'));
        });
    },
    [handleSubmitSuccess, postItems, t, toast]
  );

  const { importedItems, validationState, resetImportedAssortmentStates, loadingValidateItems } =
    useAssortmentImport({ assortmentId: draftAssortmentId });

  const onImportItems = useCallback(() => {
    const validItems = importedItems.filter((item) => isValidItem(item, validationState));
    postItems({ body: validItems })
      .then(() => handleSubmitSuccess())
      .catch((err) => console.error(err));
  }, [handleSubmitSuccess, importedItems, postItems, validationState]);

  useEffect(() => {
    if (selectedTab !== DraftTabs.IMPORT) resetImportedAssortmentStates();
    if (selectedTab !== DraftTabs.ADD_ITEMS) {
      setCheckedItems([]);
      resetFilters();
    }
    if (selectedTab !== DraftTabs.CENTRAL_ASSORTMENTS) setCheckedAssortmentItems([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const handleSubmit = useCallback(() => {
    if (selectedTab === DraftTabs.ADD_ITEMS) {
      onSubmit(checkedItems);
    } else if (selectedTab === DraftTabs.CENTRAL_ASSORTMENTS) {
      onSubmitCentralAssortmentItems(checkedAssortmentItems);
    } else {
      onImportItems();
    }
  }, [
    checkedAssortmentItems,
    checkedItems,
    onImportItems,
    onSubmit,
    onSubmitCentralAssortmentItems,
    selectedTab,
  ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{ padding: 4, '.MuiPaper-root': { gap: 0 } }}
    >
      <DialogTitle>
        <AddItemsTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexGrow: 1 }}>
        <Stack display="flex" flexGrow={1}>
          {selectedTab === DraftTabs.ADD_ITEMS && (
            <SuspenseWithSentryErrorBoundary>
              <MgSupplierItemSearchContainer
                assortmentId={draftAssortmentId}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
              />
            </SuspenseWithSentryErrorBoundary>
          )}

          {selectedTab === DraftTabs.IMPORT && (
            <Stack display="flex" flexGrow={1} justifyContent="center">
              <UploadAssortmentContainer isLarge />
            </Stack>
          )}
          {selectedTab === DraftTabs.CENTRAL_ASSORTMENTS && (
            <SuspenseWithSentryErrorBoundary>
              <CentralAssortmentOverview
                checkedAssortmentItems={checkedAssortmentItems}
                setCheckedAssortmentItems={setCheckedAssortmentItems}
              />
            </SuspenseWithSentryErrorBoundary>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <TextButton onClick={onClose}>{t('myAssortment.cancel')}</TextButton>
        <ContainedButton
          color="secondary"
          disabled={
            checkedItems.length === 0 &&
            importedItems.length === 0 &&
            checkedAssortmentItems.length === 0
          }
          loading={loadingValidateItems || loadingPostItems}
          onClick={handleSubmit}
          size="small"
        >
          {selectedTab === DraftTabs.IMPORT
            ? t('myAssortment.actions.addApprovedItemsToDraft')
            : t('myAssortment.actions.addItemsToDraft')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(AddItemsToDraft);
