import { FreightCondition } from '@retail/calculus/types';
import { Department } from '@retail/auth/types';
import { HierarchyLevels } from '@retail/calculus/types';
import { useToast } from '@retail/hooks';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAppTFunction } from '@retail/app/i18n';
import { CreateOrEditFreightCondition } from '@retail/freight/types';
import { getDate } from '@retail/utils';
import { useMutateCreateOrUpdateConditions } from '@retail/calculus/data-access';

interface Props {
  draftAssortmentId: number;
  departmentId: Department['departmentId'];
}

export const useFreightConditionActions = ({ draftAssortmentId, departmentId }: Props) => {
  const t = useAppTFunction();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: postFreightDraftConditions, isLoading: isLoadingPost } =
    useMutateCreateOrUpdateConditions({ assortmentId: draftAssortmentId, departmentId });

  const handleInvalidateFreight = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
    queryClient.invalidateQueries({ queryKey: ['product-groups-hierarchy'] });
  }, [queryClient]);

  const deleteFreightCondition = (freightCondition: FreightCondition, onClose: () => void) => {
    postFreightDraftConditions({
      body: {
        freightConditions: [
          {
            mgSupplierId: freightCondition.mgSupplierId,
            mgSupplierConditions:
              freightCondition.condition.bindingType === HierarchyLevels.supplier
                ? [
                    {
                      freight: null,
                      validFrom: getDate(),
                    },
                  ]
                : null,
            productGroupConditions: freightCondition.condition.productGroup
              ? [
                  {
                    freight: null,
                    validFrom: getDate(),
                    groupNumber: freightCondition.condition.productGroup?.groupNumber,
                  },
                ]
              : null,
            discountGroupConditions: freightCondition.condition.discountGroup?.code
              ? [
                  {
                    freight: null,
                    groupCode: freightCondition.condition.discountGroup.code,
                    validFrom: getDate(),
                  },
                ]
              : null,
            mgSupplierItemConditions: freightCondition.mgItemNumber
              ? [
                  {
                    freight: null,
                    validFrom: getDate(),
                    mgItemNumber: freightCondition?.mgItemNumber,
                  },
                ]
              : null,
          },
        ],
      },
    })
      .then(() => {
        toast.success(t('calculus.actions.conditionDeleteInDrawer'));
        handleInvalidateFreight();
        onClose();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('common.genericError'));
      });
  };

  const editOrCreateFreightCondition = (
    freightCondition: CreateOrEditFreightCondition,
    onClose: () => void
  ) => {
    postFreightDraftConditions({
      body: {
        freightConditions: [
          {
            mgSupplierId: freightCondition.mgSupplierId,
            mgSupplierConditions:
              !freightCondition.groupNumber &&
              !freightCondition.mgItemNumber &&
              !freightCondition.discountGroupCode
                ? [
                    {
                      freight: {
                        percentAddon: freightCondition.freight?.percentAddon,
                      },
                      validFrom: getDate(freightCondition.validFrom),
                    },
                  ]
                : null,
            productGroupConditions: freightCondition.groupNumber
              ? [
                  {
                    freight: {
                      percentAddon: freightCondition.freight?.percentAddon,
                    },
                    validFrom: getDate(freightCondition.validFrom),
                    groupNumber: freightCondition.groupNumber,
                  },
                ]
              : null,
            discountGroupConditions: freightCondition.discountGroupCode
              ? [
                  {
                    freight: {
                      percentAddon: freightCondition.freight?.percentAddon,
                    },
                    groupCode: freightCondition.discountGroupCode,
                    validFrom: getDate(),
                  },
                ]
              : null,
            mgSupplierItemConditions: freightCondition.mgItemNumber
              ? [
                  {
                    validFrom: getDate(freightCondition.validFrom),
                    mgItemNumber: freightCondition.mgItemNumber,
                    freight: {
                      percentAddon: freightCondition.freight?.percentAddon,
                    },
                    fixedAddOn: freightCondition.fixedAddon,
                    fixedAddOnUnit: freightCondition.fixedAddonUnit,
                  },
                ]
              : null,
          },
        ],
      },
    })
      .then(() => {
        toast.success(t('calculus.actions.conditionCreateInDrawer'));
        queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
        queryClient.invalidateQueries({ queryKey: ['freight'] });
        onClose();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('markup.error.generic'));
      });
  };

  return {
    deleteFreightCondition,
    editOrCreateFreightCondition,
    isLoadingPost,
  };
};
