import { useMemo } from 'react';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useMarkupTFunction } from '@retail/markup/i18n';
import {
  calculateSalesCoverage,
  columnsManagementSlotProps,
  DATA_GRID_ID,
  getDataGridLocalization,
  getInitialHiddenDataGridColumnsFromLocalStorage,
  MgPrisLocale,
  saveHiddenDataGridColumnsToLocalStorage,
} from '@retail/utils';
import { dataGridBoldClass } from '@shared/styles';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { Supplier } from '@retail/suppliers/types';
import { AssortmentType, HierarchyLevels, MarkupCondition } from '@retail/calculus/types';
import { CustomColumnMenu, StackedTextCell } from '@retail/components';
import { LevelChip } from '@retail/calculus/components';
import { MarkupTableActions } from './MarkupTableActions';
import { getTogglableDataGridColumns } from '@shared/utils';
import { InfinityIcon } from '@shared/custom-icons';

interface Props {
  supplier: Supplier;
  groupNumber?: string;
  language: string;
  markups: MarkupCondition[];
  handleDeactivate: (markup: MarkupCondition) => void;
  handleEdit: (markup: MarkupCondition) => void;
  handleDelete: (markupId: number) => void;
}

const baseColumnProps: Partial<GridColDef<MarkupCondition>> = {
  width: 140,
  sortable: false,
  headerAlign: 'left',
  align: 'left',
};

export function MarkupsTable({
  supplier,
  markups,
  language,
  handleDeactivate,
  handleEdit,
  handleDelete,
}: Props) {
  const t = useMarkupTFunction();

  const columns: GridColDef<MarkupCondition>[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        cellClassName: () => dataGridBoldClass,
        field: 'level',
        headerName: t('markup.level'),
        width: 190,
        renderCell: ({ row }) => {
          return (
            <LevelChip
              bindingType={row.condition.bindingType}
              active={row.assortmentType === AssortmentType.BASE}
              packageType={
                ['SECONDARY', 'TERTIARY'].includes(row.markupPackageType + '')
                  ? (row.markupPackageType as 'SECONDARY' | 'TERTIARY')
                  : undefined
              }
            />
          );
        },
      },
      {
        ...baseColumnProps,
        cellClassName: () => dataGridBoldClass,
        field: 'name',
        headerName: t('markup.levelOrItemName'),
        width: 260,
        renderCell: ({ row }) => {
          const name = row.getLevelName();
          const id = row.getLevelId();

          return <StackedTextCell topLine={name} bottomLine={id} />;
        },
      },
      {
        ...baseColumnProps,
        field: 'markupName',
        headerName: t('markup.markupName'),
        width: 220,
        renderCell: ({ row }) => row.markupTemplateName,
      },
      {
        ...baseColumnProps,
        field: 'salesFactor',
        headerName: t('markup.salesFactor'),
        renderCell: ({ row }) => row.salesFactor,
      },
      {
        ...baseColumnProps,
        field: 'salesCoverage',
        width: 100,
        headerName: t('markup.salesCoverage'),
        renderCell: ({ row }) => calculateSalesCoverage(row.salesFactor),
      },
      {
        ...baseColumnProps,
        field: 'seeItemPrices',
        headerName: t('markup.salesPrice'),
        renderCell: ({ row }) => '-',
      },
      {
        ...baseColumnProps,
        field: 'validFrom',
        width: 140,
        headerName: t('markup.fromDate'),
        renderCell: ({ row }) => dayjs(row.validFrom).format('ll'),
      },
      {
        ...baseColumnProps,
        field: 'validTo',
        width: 140,
        headerName: t('markup.toDate'),
        renderCell: ({ row }) => (row.validTo ? dayjs(row.validTo).format('ll') : <InfinityIcon />),
      },
      {
        ...baseColumnProps,
        field: 'source',
        width: 100,
        headerName: t('markup.source'),
        renderCell: ({ row }) => row.source ?? '-',
      },

      {
        ...baseColumnProps,
        field: 'actions',
        headerName: '',
        width: 90,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <MarkupTableActions
            handleDeactivate={
              row.assortmentType !== AssortmentType.DRAFT ? () => handleDeactivate(row) : undefined
            }
            handleEdit={() => handleEdit(row)}
            handleDelete={
              row.assortmentType === AssortmentType.DRAFT ? () => handleDelete(row.id) : undefined
            }
          />
        ),
      },
    ],
    [t, supplier.name, supplier.mgSupplierId, handleDeactivate, handleEdit, handleDelete]
  );

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPremium
          getRowId={(row) => `${row.id}${row.markupTemplateId}`}
          columns={columns}
          rows={markups}
          rowHeight={70}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...getInitialHiddenDataGridColumnsFromLocalStorage(
                  DATA_GRID_ID.CALCULUS_MARKUP_TABLE,
                  getTogglableDataGridColumns(columns)
                ),
              },
            },
            pinnedColumns: { right: ['actions'] },
          }}
          onColumnVisibilityModelChange={(_, details) => {
            const toggableColumns = getTogglableDataGridColumns(columns);
            saveHiddenDataGridColumnsToLocalStorage(
              DATA_GRID_ID.CALCULUS_MARKUP_TABLE,
              toggableColumns,
              details.api.state.columns.columnVisibilityModel
            );
          }}
          localeText={getDataGridLocalization(language as MgPrisLocale)}
          disableRowSelectionOnClick
          disableColumnResize
          disableColumnReorder
          hideFooter
          slots={{
            columnMenu: CustomColumnMenu,
          }}
          slotProps={{
            ...columnsManagementSlotProps,
          }}
        />
      </Box>
    </Box>
  );
}
