import { Radio } from '@mui/material';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useMemo } from 'react';
import { PurchaseCondition } from '@retail/calculus/types';
import { LevelChip } from '@retail/calculus/components';

interface Props {
  agreements: PurchaseAgreement[];
  selectedAgreement: number;
  prevSelectedAgreement?: PurchaseCondition;
  selectAgreement: (agreementId: number) => void;
}

const baseColumnProps: Partial<GridColDef<PurchaseAgreement>> = {
  minWidth: 200,
  flex: 1,
  sortable: false,
};

export function AgreementSelect({
  agreements,
  selectedAgreement,
  prevSelectedAgreement,
  selectAgreement,
}: Props) {
  const t = usePurchaseAgreementsTFunction();

  const columns: GridColDef<PurchaseAgreement>[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'action',
        width: 60,
        headerName: '',
        renderCell: ({ row }) => {
          return (
            <Radio
              checked={row.id === selectedAgreement}
              onChange={() => selectAgreement(row.id)}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'name',
        headerName: t('purchaseAgreements.agreement.columns.agreementText'),
      },
      {
        ...baseColumnProps,
        field: 'id',
        headerName: t('purchaseAgreements.agreement.columns.agreementId'),
      },
      {
        ...baseColumnProps,
        field: 'level',
        headerName: t('purchaseAgreements.agreement.columns.activationLevel'),
        renderCell: ({ row }) => {
          return row.id === prevSelectedAgreement?.agreementId ? (
            <LevelChip bindingType={prevSelectedAgreement.condition.bindingType} active={true} />
          ) : null;
        },
      },
    ],
    [prevSelectedAgreement, selectAgreement, selectedAgreement, t]
  );

  return (
    <DataGridPremium
      rows={agreements}
      columns={columns}
      disableColumnMenu
      disableRowSelectionOnClick
      hideFooter
    />
  );
}
