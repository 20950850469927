import { useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridColDef, useGridApiRef } from '@mui/x-data-grid-premium';
import { Image, TextButton } from '@retail/components';
import {
  Conditions,
  FreightCondition,
  HierarchyLevels,
  MarkupCondition,
  Operation,
  PurchaseCondition,
} from '@retail/calculus/types';
import { useAppTFunction } from '@retail/app/i18n';
import { getDataGridLocalization, MgPrisLocale } from '@retail/utils';
import { ConditionTypeChip } from './ConditionTypeChip';
import { InfinityIcon } from '@shared/custom-icons';
import dayjs from 'dayjs';
import { TrashIcon } from '@sanity/icons';
import { LevelChip } from '../LevelChip';
import { ChangeType } from './ChangeType';
import { isMarkupCondition, isPurchaseCondition } from '@retail/calculus/utils';
import { mestergruppenIconUri } from '@assets/images';

interface Props {
  condition: Conditions;
  isLoading: boolean;
  language: string;
  handleDeleteConditions: (ids: number[]) => void;
  handleSetRowToSeePrices: (agreementId?: number, productGroup?: string) => void;
}

export type ConditionRow = PurchaseCondition | FreightCondition | MarkupCondition;
type GridRowCondition = GridColDef<ConditionRow>;

const baseColumnProps: Partial<GridRowCondition> = {
  width: 150,
  sortable: false,
  hideable: false,
  disableColumnMenu: true,
  headerAlign: 'center',
  align: 'center',
};

export const DraftDrawerTableContent = ({
  condition,
  isLoading,
  language,
  handleDeleteConditions,
}: Props) => {
  const t = useAppTFunction();
  const apiRef = useGridApiRef();

  const rows = useMemo(() => {
    const conditions: ConditionRow[] = [];

    if (condition.purchaseConditions.length) conditions.push(...condition.purchaseConditions);
    if (condition.freightConditions.length) conditions.push(...condition.freightConditions);
    if (condition.markupConditions.length) conditions.push(...condition.markupConditions);

    return conditions;
  }, [condition.freightConditions, condition.markupConditions, condition.purchaseConditions]);

  const columns: GridRowCondition[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'type',
        headerAlign: 'left',
        align: 'left',
        width: 180,
        headerName: t('purchaseAgreements.agreement.columns.type'),
        renderCell: ({ row, rowNode }) => {
          if (rowNode.type === 'group') return '';
          return <ConditionTypeChip calculusArea={row.getType()} />;
        },
      },
      {
        ...baseColumnProps,
        field: 'typeOfAction',
        headerAlign: 'left',
        align: 'left',
        width: 150,
        headerName: t('calculus.typeOfAction'),
        renderCell: ({ row, rowNode }) => {
          if (rowNode.type === 'group') return '';
          else {
            if (row.operation === Operation.DELETE) return t('calculus.removeCondition');

            return t('calculus.newCondition');
          }
        },
      },
      {
        ...baseColumnProps,
        field: 'level',
        headerName: t('calculus.level.changeLevel'),
        headerAlign: 'left',
        align: 'left',
        width: 180,
        renderCell: ({ row, rowNode }) => {
          if (rowNode.type === 'group') return null;

          const isPurchaseAgreement = isPurchaseCondition(row);

          return (
            <LevelChip
              bindingType={
                row.condition.bindingType ?? (row.current ? HierarchyLevels.supplier : undefined)
              }
              isAgreement={!row.id}
              packageType={isMarkupCondition(row) ? row.markupPackageType : undefined}
              labelDetails={
                isPurchaseAgreement
                  ? row.standardAgreement && (
                      <Typography variant="body3" justifyContent="center">
                        <Stack flexDirection="row" gap={0.5} alignItems="center">
                          <Image src={mestergruppenIconUri} height={15} width={15} />
                          {t('purchaseAgreements.agreement.columns.standardAgreement')}
                        </Stack>
                      </Typography>
                    )
                  : undefined
              }
              active
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'changeType',
        headerName: t('calculus.changeType'),
        headerAlign: 'left',
        align: 'left',
        width: 180,
        renderCell: ({ row, rowNode }) => {
          if (rowNode.type === 'group') return null;
          return <ChangeType condition={row} />;
        },
      },
      {
        ...baseColumnProps,
        field: 'validFrom',
        headerName: t('calculus.activeFrom'),
        renderCell: ({ value, rowNode }) => {
          if (rowNode.type === 'group') return null;
          return value ? dayjs(value).format('ll') : '-';
        },
      },
      {
        ...baseColumnProps,
        field: 'validTo',
        headerName: t('calculus.activeTo'),
        renderCell: ({ value, rowNode }) => {
          if (rowNode.type === 'group') return null;
          return value ? dayjs(value).format('ll') : <InfinityIcon />;
        },
      },
      {
        field: 'actions',
        headerName: '',
        align: 'right',
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ row, rowNode }) => {
          if (rowNode.type === 'group') return null;

          return (
            <TextButton disabled={isLoading} onClick={() => handleDeleteConditions([row.id])}>
              {t('calculus.delete')}{' '}
              <Box display="flex" ml={0.5} alignItems="center">
                <TrashIcon fontSize="20px" />
              </Box>
            </TextButton>
          );
        },
      },
    ],
    [handleDeleteConditions, isLoading, t]
  );

  return (
    <Box height="100%">
      <DataGridPremium<ConditionRow>
        getRowId={(row) => row.id}
        apiRef={apiRef}
        columns={columns}
        rows={rows}
        rowHeight={70}
        disableRowSelectionOnClick
        disableColumnResize
        disableColumnReorder
        hideFooter
        localeText={getDataGridLocalization(language as MgPrisLocale)}
        groupingColDef={{
          hideDescendantCount: true,
        }}
        slots={{
          noRowsOverlay: () => (
            <Stack alignItems="center" justifyContent="center" gap={2} height="100%">
              <Typography variant="h3" color="text.secondary">
                {t('calculus.messages.noUpdates')}
              </Typography>
            </Stack>
          ),
        }}
        sx={{
          '.MuiDataGrid-row:hover': {
            cursor: 'auto',
          },

          '.MuiDataGrid-topContainer': {
            paddingLeft: (theme) => theme.spacing(4),
            paddingRight: (theme) => theme.spacing(4),
          },

          '.MuiDataGrid-row': {
            paddingLeft: (theme) => theme.spacing(4),
            paddingRight: (theme) => theme.spacing(4),
          },
        }}
      />
    </Box>
  );
};
