import { useCallback, useEffect, useMemo } from 'react';
import { Box, Checkbox, Stack, Typography } from '@mui/material';
import {
  DataGridPremium,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { Conditions } from '@retail/calculus/types';
import { useAppTFunction } from '@retail/app/i18n';
import { DraftDrawerTableContent } from './DraftDrawerTableContent';
import { CustomDetailPanelToggle } from './CustomDetailPanelToggle';

interface Props {
  draftConditions: Conditions[];
  selectedConditionSupplierIds: number[];
  isLoading: boolean;
  language: string;
  handleDeleteConditions: (ids: number[]) => void;
  handleSetRowToSeePrices: (agreementId?: number, productGroup?: string) => void;
  onSelectConditionSupplierIds: (supplierIds: number[]) => void;
}

type GridRowCondition = GridColDef<Conditions>;

const baseColumnProps: Partial<GridRowCondition> = {
  width: 150,
  sortable: false,
  filterable: false,
  hideable: false,
  headerAlign: 'left',
  align: 'left',
};

export const DraftDrawerTable = ({
  draftConditions,
  selectedConditionSupplierIds,
  isLoading,
  language,
  handleDeleteConditions,
  handleSetRowToSeePrices,
  onSelectConditionSupplierIds,
}: Props) => {
  const t = useAppTFunction();
  const apiRef = useGridApiRef();

  const handleSupplierCheckboxClicked = useCallback(
    (mgSupplierId: GridRowId) => {
      const updatedSupplierIds = selectedConditionSupplierIds.includes(mgSupplierId as number)
        ? selectedConditionSupplierIds.filter((supplierId) => supplierId !== mgSupplierId)
        : [...selectedConditionSupplierIds, mgSupplierId];

      onSelectConditionSupplierIds(updatedSupplierIds as number[]);
    },
    [onSelectConditionSupplierIds, selectedConditionSupplierIds]
  );

  const findSupplierName = useCallback(
    (mgSupplierId: number) => {
      const draftCondition = draftConditions.find(
        (condition) => condition.mgSupplierId === mgSupplierId
      );
      const firstFoundElement =
        draftCondition?.purchaseConditions?.[0] ??
        draftCondition?.freightConditions?.[0] ??
        draftCondition?.markupConditions?.[0];

      return firstFoundElement?.mgSupplierName ?? mgSupplierId;
    },
    [draftConditions]
  );

  const handleRowSelection = (rowSelectionModel: GridRowSelectionModel) => {
    const selection = rowSelectionModel[0];
    apiRef.current?.toggleDetailPanel(selection);
  };

  const columns: GridRowCondition[] = useMemo(
    () => [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        field: 'checkbox',
        align: 'center',
        renderCell: ({ id }) => {
          return (
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
                handleSupplierCheckboxClicked(id);
              }}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'mgSupplierId',
        flex: 1,
        headerName: t('markup.supplier'),
        renderCell: ({ rowNode, field, value }) => {
          if (rowNode.type === 'group' && field === rowNode.groupingField) {
            return '';
          }

          return (
            <Typography variant="body2" fontWeight={600}>
              {findSupplierName(value) ?? value}
            </Typography>
          );
        },
      },
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        align: 'right',
        renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
      },
    ],
    [findSupplierName, handleSupplierCheckboxClicked, t]
  );

  const getDetailPanelContent = useCallback(
    (mgSupplierId: GridRowId) => {
      const panelCondition = draftConditions.find(
        (condition) => condition.mgSupplierId === mgSupplierId
      );

      return (
        <Box height="100%">
          <DraftDrawerTableContent
            condition={panelCondition!}
            isLoading={isLoading}
            language={language}
            handleDeleteConditions={handleDeleteConditions}
            handleSetRowToSeePrices={handleSetRowToSeePrices}
          />
        </Box>
      );
    },
    [draftConditions, handleDeleteConditions, handleSetRowToSeePrices, isLoading, language]
  );

  useEffect(() => {
    if (draftConditions.length === 1) {
      apiRef?.current?.setExpandedDetailPanels([draftConditions[0].mgSupplierId]);
    }
  }, [apiRef, draftConditions]);

  return (
    <Box height="100%">
      <DataGridPremium
        apiRef={apiRef}
        getRowId={(row) => row.mgSupplierId}
        columns={columns}
        rows={draftConditions}
        rowHeight={50}
        columnHeaderHeight={0}
        getDetailPanelContent={({ id }) => getDetailPanelContent(id)}
        getDetailPanelHeight={() => 'auto'}
        hideFooter
        rowSelection
        onRowSelectionModelChange={(newRowSelectionModel) =>
          handleRowSelection(newRowSelectionModel)
        }
        slots={{
          noRowsOverlay: () => (
            <Stack alignItems="center" justifyContent="center" gap={2} height="100%">
              <Typography variant="h3" color="text.secondary">
                {t('calculus.messages.noUpdates')}
              </Typography>
            </Stack>
          ),
        }}
        sx={{
          '.MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
        }}
      />
    </Box>
  );
};
